import React from "react"
// import CES from "../components/auxilllary/CES"
import SEO from "../components/SEO"

function CESPage() {
  return (
    <SEO
      title="CES 2020"
      description=" Dive into our on demand “Marketing Reinvented” program track,
      featuring tech pioneers in marketing, entertainment, media and
      content exploring disruptive trends; as well as our official CES C
      Space Keynote. We’ve also partnered with Yahoo to bring you a
      virtual tour recorded directly from the CES show floor, giving you
      an up-close look at the exhibitors on the ground in Vegas making the
      biggest waves that will matter profoundly to the media and marketing
      community in 2022 and beyond."
    />
  )
}

export default CESPage
